<template lang="pug">
// 新增聯絡人
v-card
  //- v-card-title.d-flex.justify-center 修改聯絡人－ {{editMainContact.full_name}}
  v-card-title.d-flex.justify-center {{$t('ADDMAINCONTACT.TITLE_2')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-row(no-gutters="no-gutters")
        v-text-field.mb-4(
            v-model="editMainContact.full_name",
            :label=`$t('ADDMAINCONTACT.CONTACT_NAME')`,
            large="large",
            :readonly='true'
          )
        v-btn.ml-1(icon color='gary' @click='goEditContact')
            v-icon(size='18' padding="10") icon-edit
      v-text-field.no-border(
        v-model="editMainContact.organizationcontacts_set[0].juristic_person_name",
        prepend-icon="mdi-mail",
        :label=`$t('ADDMAINCONTACT.JURISTIC_PERSON_NAME')`,
		:rules='formRules.contentLenRule50'
      )
      v-text-field.no-border(
        v-model="editMainContact.organizationcontacts_set[0].position",
        prepend-icon="mdi-mail",
		:rules='formRules.contentLenRule20'
        :label=`$t('ADDMAINCONTACT.POSITION')`,
      )
      v-text-field.no-border(
        v-model="editMainContact.organizationcontacts_set[0].shareholding",
        prepend-icon="mdi-mail",
		:rules="formRules.stockRules",
        :label=`$t('ADDMAINCONTACT.SHAREHOLDING')`,
      )
      v-checkbox.no-border(
        v-model="editMainContact.organizationcontacts_set[0].main_contact",
        :label=`$t('ADDMAINCONTACT.PRIMARY_CONTACT')`,
      )
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          dark
        ) {{$t('GENERAL.SAVE')}}
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  props: {
    editMainContact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      search: null,
      organizationList: [],
      editMainContactData: {
        // id: this.editMainContact.organizationcontacts_set[0].id,
        // position: this.editMainContact.organizationcontacts_set[0].position,
        // juristic_person_name: this.editMainContact.organizationcontacts_set[0].juristic_person_name,
        // shareholding: this.editMainContact.organizationcontacts_set[0].shareholding,
        // main_contact: this.editMainContact.organizationcontacts_set[0].main_contact,
      },
    orginal_data:JSON.parse(JSON.stringify(this.editMainContact)),
    formRules: {
		stockRules: [
        (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
        (v) => /^.{0,9}$/.test(v) || i18n.t('RULE.RULE_9')
    ],
		contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
    },
    };
  },  
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async onConfirm() {
      this.editMainContactData= {
        id: this.editMainContact.organizationcontacts_set[0].id,
        position:this.editMainContact.organizationcontacts_set[0].position,
        juristic_person_name: this.editMainContact.organizationcontacts_set[0].juristic_person_name,
        shareholding: this.editMainContact.organizationcontacts_set[0].shareholding,
        main_contact: this.editMainContact.organizationcontacts_set[0].main_contact,
      };
      await OrganizationDataService.maincontactedit(this.editMainContactData["id"],this.editMainContactData);
      this.$emit("emitEditMainContactDialog", true);
    },
    onCancel() {
      this.editMainContact.organizationcontacts_set[0].position = this.orginal_data.organizationcontacts_set[0].position
      this.editMainContact.organizationcontacts_set[0].juristic_person_name = this.orginal_data.organizationcontacts_set[0].juristic_person_name
      this.editMainContact.organizationcontacts_set[0].shareholding = this.orginal_data.organizationcontacts_set[0].shareholding 
      this.editMainContact.organizationcontacts_set[0].main_contact = this.orginal_data.organizationcontacts_set[0].main_contact 
      this.$emit("emitEditMainContactDialog", false);
    },
    goEditContact(){
      this.$router.replace('/Contacts/'+this.editMainContact.id);
    }
  }
}
);
</script>