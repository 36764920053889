<template lang="pug">
// 新增聯絡人
v-card
  v-card-title.d-flex.justify-center {{$t('ADDMAINCONTACT.TITLE')}} 
  v-card-text
    v-btn( ref="newbtn" width='120' height='32' color='blue'  plain border-0 @click='onNew')
        v-icon.mr-1(size='14') icon-add
        span {{$t('ADDMAINCONTACT.NEW_CONTACT')}}
    v-btn(v-show='false' ref="oldbtn" width='120' height='32' color='blue'  plain border-0 @click='onOld')
        v-icon.mr-1(size='14')  icon-add
        span {{$t('ADDMAINCONTACT.OLD_CONTACT')}}
    v-form(v-model="valid", ref="form")
      v-row(no-gutters="no-gutters")      
      v-combobox.no-border(
        v-model="addContact.contact",
        :label=`$t('ADDMAINCONTACT.CONTACT_NAME')`,
        large="large",
        :items="contactList",
        item-text="searchStr",
        item-value="id",
        validate-on-blur,
        :loading="isLoading",
        :rules="formRules.nameRules",
        required="required",
        ref="newbtnitem",
        :search-input.sync='search',
        :menu-props="{openOnClick: true}"
        :no-data-text=`$t('RELATEDCONTACTS.NO_CONTACT')`
        @blur='contactList = []'
      )
        template(v-slot:item='data')
            v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                v-icon(v-if="data.item.image == null") mdi-account
                img(v-else :src='data.item.image')
            .t-black {{ data.item.full_name }} 
            .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
        template(v-slot:selection='data')
            .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
      v-row(v-show="false" no-gutters="no-gutters" ref="oldbtnitem",)
        v-col(cols="6")
          v-text-field(
            v-model="addContact.last_name",
            :label=`$t('ADDMAINCONTACT.LAST_NAME')`,
            :rules="formRules.nameRules",
            validate-on-blur,
            required="required",
            large="large",
          )
        v-col(cols="6")
          v-text-field(
            v-model="addContact.first_name",
            :label=`$t('ADDMAINCONTACT.FIRST_NAME')`,
            large="large"
          )
      v-text-field.no-border.height-48(
        v-model="addContact.juristic_person_name",
        prepend-icon="mdi-account",
        :rules="formRules.contentLenRule50",
        :label=`$t('ADDMAINCONTACT.JURISTIC_PERSON_NAME')`,
      )      
      v-text-field.no-border.height-48(
        v-model="addContact.position",
        prepend-icon="mdi-account",
        :rules="formRules.contentLenRule20",
        :label=`$t('ADDMAINCONTACT.POSITION')`,
      )
      v-text-field.no-border.height-48(
        v-model="addContact.shareholding",
        prepend-icon="mdi-cash-multiple",
        :rules="formRules.stockRules",
        :label=`$t('ADDMAINCONTACT.SHAREHOLDING')`,
      )
      v-checkbox.no-border.height-48(
        v-model="addContact.main_contact",
        :label=`$t('ADDMAINCONTACT.PRIMARY_CONTACT')`,
      )
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="primary",
          depressed,
          :disabled='saving'
        )
          v-progress-circular(v-if='saving' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.ADD')}}
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog()')
    error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import OrganizationDataService from "../../../services/OrganizationDataService";
import ContactDataService from '@/services/ContactDataService';
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js' 

export default Vue.extend({
  components: {
    errorDialog,
  },
  props: {
    addMainContact: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saving:false,
      errorMessage: '',
      isNewContact: false,
      valid: true,
      isLoading: false,      
      contactList: [],
      formRules: {
            nameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                (v) => /^.{0,70}$/.test(v) || i18n.t('RULE.RULE_70')
            ],
            stockRules: [
                (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                (v) => /^.{0,9}$/.test(v) || i18n.t('RULE.RULE_9')
            ],
            contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
            contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
      },
      addContact: { 
        contact: null,       
        position:null,
        juristic_person_name: null,
        shareholding: null,
        main_contact: false,
        organization: this.addMainContact,
        first_name: "",
        last_name: null
      },
      search: "",
      wrongInput: false,
      errorDialog: false,
    };
  },
  methods: {
    validateForm(){
      if(this.$refs.form.validate()){        
        return true;
      }
      else{
        return false;
      }        
    },
    changePostData(){
        if (this.addContact.contact != null){
            this.addContact.last_name = this.addContact.contact.last_name;
        }
        else if (this.addContact.last_name != null){
            this.addContact.contact = {
                id: 0,
                last_name: this.addContact.last_name,
                first_name: this.addContact.first_name,
                full_name: this.addContact.last_name+' '+this.addContact.first_name
            };
        }
    },
    async onConfirm() {
      this.saving = true;
      if (typeof(this.addContact.contact) === 'string') {
          this.errorDialog = true;
          this.errorMessage = i18n.t('ADD_DEAL.ERROR_2');
          this.saving = false;
          return;
      }
      await this.changePostData();
      if(this.validateForm()){
        if(this.isNewContact == false){
          await OrganizationDataService.maincontactcreate(this.addMainContact,this.addContact);
        }
        else{             
          await OrganizationDataService.maincontactcreate(this.addMainContact,this.addContact);
        }
        this.$emit("emitAddMainContactDialog", true);
      }
      else{
        if(this.search && this.search.length > 0){
            this.errorDialog = true;
            this.errorMessage = i18n.t('ADDMAINCONTACT.ERROR');
        }
      }
      this.saving = false;
      this.contactList = [];
    },
    onCancel() {
      this.$emit("emitAddMainContactDialog", false);
    },
    // async getContactList(){
    //   if(this.contactList.length == 0){        
    //     await OrganizationDataService.getContactList(this.addMainContact).then((response)=> {
    //       this.contactList = response.data;
    //       this.contactList.forEach(item => {
    //         if (item.first_name != null) {
    //           item.fullName = item.last_name + ' ' + item.first_name;
    //         }
    //         else {
    //           item.fullName = item.last_name;
    //         }
    //       })
    //     })
    //   }      
    // },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    onNew(){
      this.$refs.oldbtn.$el.style.display = "flex";
      this.$refs.newbtn.$el.style.display = "none";
      this.$refs.oldbtnitem.style.display = "flex";
      this.$refs.newbtnitem.$el.style.display = "none";      
      this.addContact.contact = null;
      this.addContact.last_name = null;
      this.isNewContact = true;    
    },
    onOld(){
      this.$refs.newbtn.$el.style.display = "flex";
      this.$refs.oldbtn.$el.style.display = "none";
      this.$refs.newbtnitem.$el.style.display = "flex";
      this.$refs.oldbtnitem.style.display = "none";
      this.addContact.last_name = null;
      this.addContact.contact =null;
      this.addContact.first_name =null;
      this.isNewContact = false;  
    }
  },
  watch: {
    search(val){
        // let isString; 
        // typeof(val) == 'string' ? isString = true : isString = false;

        // if(!!val && isString){
        //     this.isLoading = true;
        //     setTimeout(() => {
        //         ContactDataService.getAll(1,val,100,[])
        //         .then(response => {
        //             this.contactList = response.data.list;
        //         })
        //         .catch(response => {
        //             this.showErrorDialog(response);
        //         })
        //         .finally(()=>{
        //             this.isLoading = false;
        //         })
        //     }, 1000);
        // }
        // check user input is en / zh / num
        let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
        let isEnZhNum = false;
        pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

        // check user input data type
        let isString; 
        typeof(val) == 'string' ? isString = true : isString = false;
        let array = [];

        // search
        if(isEnZhNum && !!val && isString){
            this.isLoading = true;
            ContactDataService.getAll(1,val,100,[])
            .then(response => {
                response.data.list.forEach(item => {
                    item.searchStr = item.full_name 
                                    +item.full_name_en
                                    +item.full_name_en.toLowerCase()
                                    +item.full_name_en.toUpperCase()
                                    +item.full_name_zh;
                    if(item.searchStr.includes(val)){
                        if(item.organization_set.length > 0)
                        {
                            item.org_name = "[ "+item.organization_set[0].name +" ]";
                        }
                        array.push(item);
                    }
                })
                this.contactList = array;
            })
            .catch(response => {
                this.showErrorDialog(response);
            })
            .finally(()=>{
                this.isLoading = false;
            })
        }
        else{
            this.contactList = [];
        }
    },
  }
});
</script>