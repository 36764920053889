<template lang="pug">
v-card.dealDialog
    v-card-title.d-flex.justify-center {{$t('ADDSTATICSEGMENT.TITLE')}}
    v-card-text
        v-form(v-model='valid' ref='form')
            v-text-field.no-border(prepend-icon="mdi-chart-pie",:label=`$t('ADDSTATICSEGMENT.NAME')`,hide-details="auto")

    v-card-actions.px-2.py-4
        v-row
            v-col.pr-2(cols='6')
                v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pl-2(cols='6')
                v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
</template>

<script>
	import Vue from 'vue';

	export default Vue.extend({
        // props: {
        //     ownerData: {
        //         type: Object,
        //         required: true,
        //     },
        // },
    created(){
    },
    data() {
        return {
            valid: false,
        }
    },
    methods: {
        onCancel() {
            this.$emit('emitAddStaticSeg', false);
        },
        onConfirm() {
            this.$emit('emitAddStaticSeg', true);
        }
    }
	});
</script>