<template lang="pug">
    v-card.addContactOrgDialog
        v-card-title.d-flex.justify-center {{$t('ADDCONTACTORG.TITLE')}}
        v-card-text
            v-form(v-model='valid' ref='form')
                v-row(no-gutters="no-gutters")
                    v-combobox.no-border(v-model="contactOrgData.organization" :items="organizationList" item-text="name" item-value="uqNumber" :search-input.sync="search" :loading="isLoading" prepend-icon="mdi-domain", :label=`$t('ADDCONTACTORG.COMPANY_NAME')`, validate-on-blur required="required" :rules='formRule.nameRules')
                        template(v-slot:append-outer)
                            div(v-if="isNew")
                                v-icon(color="red", size="25") mdi-new-box
                        template(v-slot:item="data")
                            div.ellipsis(v-if="data.item.id == 0")
                                span {{ data.item.name }}
                                v-icon(color="red", size="25") mdi-new-box
                            div.ellipsis(v-else)
                                span {{ data.item.name }}
                    v-text-field.no-border(v-model="contactOrgData.juristic_person_name" prepend-icon="mdi-account", :label=`$t('ADDCONTACTORG.JURISTIC_PERSON_NAME')`, :rules="formRule.contentLenRule50")
                    v-text-field.no-border(v-model="contactOrgData.position" prepend-icon="mdi-account", :label=`$t('ADDCONTACTORG.POSITION')`, :rules="formRule.contentLenRule20")
                    v-text-field.no-border(v-model="contactOrgData.shareholding" prepend-icon="mdi-cash-multiple", :label=`$t('ADDCONTACTORG.SHAREHOLDING')`, :rules="formRule.stockRules")
        v-card-actions.px-2.py-4
            v-row
                v-col.pr-2(cols='6')
                    v-btn(:ripple='false' @click='onCancel' width='100%' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
                v-col.pl-2(cols='6')
                    v-btn(:ripple='false' @click='onConfirm' width='100%' height='40' color='success' depressed dark) {{$t('GENERAL.ADD')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import OrganizationDataService from "../../../services/OrganizationDataService";
    import ContactDataService from "../../../services/ContactDataService";
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        props: {
            contactOrgData: {
                type: Object,
                required: true,
            },
        },
        components: {
            errorDialog,
        },
        created(){
        },
        data() {
            return {
                valid: true,
                formRule: {
                    nameRules: [
                        v => !!v || i18n.t('RULE.RULE_R_COMPANY_NAME'),
                        v => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200')
                    ],
                    stockRules: [
                        (v) => /^[0-9\s]*$|(^$)|null/.test(v) || i18n.t('RULE.RULE_NUM'),
                        (v) => /^.{0,10}$/.test(v) || i18n.t('RULE.RULE_10')
                    ],
                    contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
                    contentLenRule20: [(v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20')],
                },
                organizationList: [],
                isLoading: false,
                search: null,
                comboText: '',
                errorDialog: false,
                errorMessage: '',
            }
        },
        methods: {
            validateForm(){
                if(this.$refs.form.validate())
                    return true;
                else
                    return false;
            },
            onConfirm() {
                if(this.validateForm()){
                    var indexId = this.contactOrgData.orgIdList.indexOf(this.contactOrgData.organization.id);
                    var indexName = this.contactOrgData.orgNameList.indexOf(this.contactOrgData.organization.name);
                    var indexString = this.contactOrgData.orgNameList.indexOf(this.comboText);
                    if (indexId != -1 || indexName != -1 || indexString != -1) {
                        this.showErrorDialog(i18n.t('ADDCONTACTORG.ERROR'))
                    } else {
                        let newOrg = {id: "-1", name: this.comboText, company_number: "0"};
                        let createContactOrgData = {
                            contact_id: this.contactOrgData.contact_id,
                            position: this.contactOrgData.position == "" ? null : this.contactOrgData.position,
                            juristic_person_name: this.contactOrgData.juristic_person_name == "" ? null : this.contactOrgData.juristic_person_name,
                            shareholding: this.contactOrgData.shareholding == "" ? null : this.contactOrgData.shareholding,
                            organization: {
                                id: this.contactOrgData.organization.id,
                                name: this.contactOrgData.organization.name,
                                company_number: this.contactOrgData.organization.company_number
                            }
                        }
                        if (this.comboText != '' && this.comboText != null) {
                            if (this.contactOrgData.organization.name != this.comboText) {
                                createContactOrgData.organization = newOrg; // 無資料的公司
                            }
                        } else {
                            // this.showErrorDialog("未輸入公司名稱");
                            return;
                        }
                        ContactDataService.createContactOrg(createContactOrgData)
                        .then(response => {
                            if (this.isNew == true) {
                                let principalData = {
                                    "organization_id": response.data.organization.id,
                                    "owner_id": this.contactOrgData.owner.id
                                };
                                ContactDataService.createCompanyPrincipal(principalData);
                            }
                            this.$emit('emitAddContactOrgData', response.data);
                            this.$emit('emitAddContactOrgDialog', true);
                        });
                    }
                }
            },
            onCancel() {
                this.$emit('emitAddContactOrgDialog', false);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
        },
        computed: {
            isNew: function(){
                if(this.contactOrgData.organization == ""){
                    return false;
                }
                if(typeof (this.contactOrgData.organization) === 'object'){
                    if(this.contactOrgData.organization.id == 0){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    return true;
                }
            },
        },
        watch: {
            async search(val) { 
                if(val != null){
                    let charNum = val.split(/[\u4e00-\u9fa5_a-zA-Z0-9\s]/).length -1;
                    var checkLength = /^.{0,200}$/.test(val);
                    this.comboText = val;
                    if(!this.isLoading && charNum>=2 && charNum==val.length && checkLength){
                        this.isLoading = true;
                        await OrganizationDataService.findOrganizationByNamefromDB(val)
                        .then((response) => {
                            this.organizationList = response.data;
                            let num = 0
                            this.organizationList.forEach((item)=>{
                            item.uqNumber = num
                            num ++
                            })
                            })
                        .finally(() => (this.isLoading = false));
                    }
                    else {
                        this.isLoading = false;
                    }
                }
            },
        },
    });
</script>

